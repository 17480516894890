// eslint-disable-next-line no-undef
const defaultFetchOptions: RequestInit = {
  method: "GET",
  mode: "no-cors",
  cache: "no-cache",
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
};

// eslint-disable-next-line no-undef
export const APICall = async (route: string, payload?: RequestInit) => {
  const response = await fetch(route, { ...defaultFetchOptions, ...payload });
  if (response.status > 400) {
    throw new Error(`Error: ${response.status} in ${route}`);
  }
  return response.json();
};

export const APIRoutes = {
  fetchUser: () => `${process.env.NEXT_PUBLIC_SHOPIRISE_API_URL}/users/me`,
};
