import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { APICall, APIRoutes } from "../../lib/APIUtils";

export const fetchUser = createAsyncThunk("user/fetch", async () => {
  const response = await APICall(APIRoutes.fetchUser());
  return response.json();
});

export const userSlice = createSlice({
  name: "user",
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => action.payload)
      .addDefaultCase((state) => state);
  },
});

export const getUser = (state) => state && state.user;

export default userSlice.reducer;
