import cx from "classnames";
import React, { FC } from "react";
import CookieConsentTool from "react-cookie-consent";
import { useDispatch } from "react-redux";

import useHasUserScrolled from "../../../lib/hasUserScrolledHook";
import { setCookieConsent } from "../../../redux/slices/cookieConsentSlice";

type CookieConsentProps = {};

const CookieConsent: FC<CookieConsentProps> = () => {
  const hasUserScrolled = useHasUserScrolled();
  const dispatch = useDispatch();

  return (
    <div
      className={cx(
        hasUserScrolled ? "fixed top-0 right-0 left-0" : "relative",
        "z-50 bg-gray-800 flex justify-center"
      )}
    >
      <CookieConsentTool
        location="top"
        buttonText="Accept cookies"
        declineButtonText="Decline all"
        containerClasses="max-w-6xl"
        declineButtonStyle={{
          backgroundColor: "inherit",
          color: "#fff",
          borderRadius: 5,
          padding: "8px 10px",
          textDecoration: "underline",
        }}
        buttonStyle={{
          backgroundColor: "#3d9a00",
          color: "#fff",
          borderRadius: 5,
          padding: "8px 10px",
        }}
        enableDeclineButton
        contentStyle={{
          flex: "1 0 300px",
        }}
        style={{
          position: "relative",
          background: "transparent",
        }}
        onAccept={() => dispatch(setCookieConsent(true))}
      >
        This site uses cookies to provide you with the best user experience.
      </CookieConsentTool>
    </div>
  );
};

export default CookieConsent;
