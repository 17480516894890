import { createSlice } from "@reduxjs/toolkit";
import { getCookieConsentValue } from "react-cookie-consent";

export const cookieConsentSlice = createSlice({
  name: "cookieConsent",
  initialState: Boolean(getCookieConsentValue()),
  reducers: {
    setCookieConsent(state, action) {
      return action.payload;
    },
  },
});

export const getCookieConsent = (state) => state.cookieConsent;

export const { setCookieConsent } = cookieConsentSlice.actions;
export default cookieConsentSlice.reducer;
